<template>
    <div id="policybox">
        <div class="hadheight"></div>
        <Header/>
        <div class="policybox-sec1 base100">
            <div class="basecont">
                <!-- {{xie}} -->
                <div class="f_font">
                    <div class="tit basefont40">{{font.catname}}</div>
                    <div class="name">
                        <span>{{font.uptime}}</span>
                        <span>{{font.sxtime}}</span>
                    </div>
                </div>
                <div class="make-text" v-html="font.content"></div>
                <!-- <div class="make-text">
                    <h3 style="margin-bottom: 16px">第一条</h3>
                    <p>
                        本网站所刊载的所有资料及图表仅供参考使用。刊载这些文档并不构成对任何股份的收购、购买、认购、抛售或持有的邀约或意图。参阅本网站上所刊的文档的人士，应被视为已确认得悉上述立场。投资者依据本网站提供的信息、资料及图表进行金融、证券等投资项目所造成的盈亏与本网站无关。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第二条</h3>
                    <p>
                        本网站的用户在参加网站举办的各种活动时，我们将在您的同意及确认下，通过注册表格等形式要求您提供一些个人资料，如∶您的姓名、性别、年龄、出生日期、身份证号、家庭住址、教育程度、企业情况、所属行业等。请您绝对放心，我们在未经您同意的情况下，绝对不会将您的任何资料以任何方式泄露给任何第三方。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第三条</h3>
                    <p>
                        当政府司法机关依照法定程序要求本网站披露个人资料时，我们将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本网站均得免责。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第四条</h3>
                    <p>
                        由于用户将个人密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露，本网站不负任何责任。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第五条</h3>
                    <p>
                        任何由于黑客攻击、计算机病毒侵人或发作、因政府管制而造成的.暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，本网站均得免责。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第六条</h3>
                    <p>
                        由于与本网站链接的其他网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，本网站均得免责。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第七条</h3>
                    <p>
                        本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本企业控制范围外的硬件故障或其他不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第八条</h3>
                    <p>
                        本网站使用者因为违反本声明的规定而触犯中华人民共和国法律的，一切后果自己负责，本网站不承担任何责任。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第九条 </h3>
                    <p>
                        凡以任何方式登录本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第十条</h3>
                    <p>
                        本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。
                    </p>
                    <p>
                        <br>
                    </p>
                    <h3 style="margin-bottom: 16px">第十一条</h3>
                    <p>
                        本网站之声明以及其修改权、更新权及最终解释权均属xxxx有限公司所有。
                    </p>
                </div> -->
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default{
    name: "Policy",
    components:{
        Header,
        Footer
    },
    data(){
        return {
            xie: "",
            font: []
        }
    },
    mounted(){
        document.title="免责声明";
        this.xie=this.$route.query.title;
        this.$axios.post('/api/Column/getDisclaimers').then(res=>{
            if(res.data){
                this.font = res.data;
            }
        }).catch(error => {
            console.log(error);
        });
    }
}
</script>
